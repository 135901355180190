import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { GuestPartnerHotelBookingRedirectParams, useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useIsPartnerHotelValidQuery } from '@graphql/generated';
import { PartnerLinkSearchParams } from '@shared/hooks/useHotelBookingPartnerLink';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import queryString from 'query-string';
import { config } from '@static/js/env.config';
import { accommodationPlaceReplaceUrl } from '../AccommodationPlacePartner/AccommodationPlacePartner.utils';
import { ensureHttpsPrefix } from '@shared/utils/urlHelpers';
import { AnalyticsContext } from '@shared/core';
import { useCheckInCheckoutDates } from '../../hooks/useCheckInCheckoutDates';

enum HotelIdType {
  stays = 'stays',
  joy = 'joy',
  hotels = 'hotels'
}

export const HotelBookingPartner: React.FC<{ hotelId?: string }> = ({ hotelId }) => {
  const redirectInProgress = useRef(false);
  const [safeLink, setSafeLink] = useState<string | undefined>();
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo, loading: eventInfoLoading } = useEventInfo();
  const eventDate = eventInfo?.finalizedDate?.dateString || undefined;
  const { anonymousId } = useContext(AnalyticsContext);

  const { value: travelHotelLinkRedirectValue, loading: travelHotelLinkRedirectLoading } = useFeatureValue('travelHotelLinkRedirectEnabled');
  const travelHotelLinkRedirectEnabled = travelHotelLinkRedirectValue === 'on';

  const queryParams = useQueryParams();

  const getStringQueryParam = useEventCallback((key: string) => {
    const value = queryParams[key] || queryParams[key.toLowerCase()];
    return Array.isArray(value) ? value[0] : value ?? undefined;
  });

  const idTypeParam = getStringQueryParam(PartnerLinkSearchParams.idType) || '';
  const isValidHotelIdType = Object.keys(HotelIdType).includes(idTypeParam); // Validate is valid idtype
  const idType: HotelIdType = isValidHotelIdType ? ((idTypeParam as unknown) as HotelIdType) : HotelIdType.hotels; // convert value to HotelIdType
  const isZhId = idType === HotelIdType.hotels;

  const { checkIn, checkOut, isCheckInOrCheckoutValid, message: checkInCheckOutMessage } = useCheckInCheckoutDates({ eventDate });

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const accommodationsGuestSafeLinkEnabled = useFeatureValue('accommodationsGuestSafeLinkEnabled').value === 'on';
  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';
  const { value: zentrumHubEnabled, loading: featureFlagsLoading } = useFeatureValue('zentrumHubEnabled');
  const shouldSendUsersToZentrumHubWhiteLabel =
    useFeatureValue('zentrumHubPartnerEnabled', {
      skip: zentrumHubEnabled !== 'on'
    }).value === 'on';

  const hotelIdAsNumber = parseInt(hotelId || '', 10);
  const { data, loading: isPartnerHotelValidQueryLoading } = useIsPartnerHotelValidQuery({
    variables: {
      payload: {
        zhId: hotelIdAsNumber,
        ...(isCheckInOrCheckoutValid ? { checkIn, checkOut } : {})
      }
    },
    batchMode: 'off',
    skip: isNaN(hotelIdAsNumber) || eventInfoLoading || !isZhId
  });

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (
      redirectInProgress.current ||
      eventInfoLoading ||
      featureFlagsLoading ||
      travelHotelLinkRedirectLoading ||
      (shouldSendUsersToZentrumHubWhiteLabel && isPartnerHotelValidQueryLoading)
    ) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(checkInCheckOutMessage);

    const { isValid, hasRates, hasInventory } = data?.zentrumHubHotelIsValid || {};
    const hotelWebsite = getStringQueryParam(PartnerLinkSearchParams.hotelWebsite);
    const lat = getStringQueryParam(PartnerLinkSearchParams.latitude);
    const lng = getStringQueryParam(PartnerLinkSearchParams.longitude);
    const gplid = getStringQueryParam(PartnerLinkSearchParams.googlePlaceId);
    const name = getStringQueryParam(PartnerLinkSearchParams.name);
    const address = getStringQueryParam(PartnerLinkSearchParams.address);
    const source = getStringQueryParam(PartnerLinkSearchParams.source);
    const telemetryPayload: Pick<GuestPartnerHotelBookingRedirectParams, 'zentrumHub' | 'hotelWebsite' | 'googleMaps' | 'source'> = {
      source,
      zentrumHub: hotelId && isZhId ? { id: hotelId, isValid, hasRates, hasInventory } : undefined,
      hotelWebsite: hotelWebsite ? { value: hotelWebsite } : undefined,
      googleMaps:
        gplid || lat || lng || name || address
          ? {
              gplid,
              name,
              address,
              lat,
              lng
            }
          : undefined
    };

    /**
     * Try redirect to nuitee white label
     */
    if (hotelId && idType === HotelIdType.stays && travelHotelLinkRedirectEnabled) {
      const externalPDPUrl = new URL(`${config.nuiteeWhitelableBaseUrl}/${hotelId}`);
      const clientReference = `${eventInfo?.eventId}_${anonymousId ?? ''}`;
      externalPDPUrl.searchParams.append('clientReference', clientReference || '');
      externalPDPUrl.searchParams.append('checkin', checkIn);
      externalPDPUrl.searchParams.append('checkout', checkOut);
      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'externalPDPUrl',
        destinationUrl: externalPDPUrl,
        ...{ ...telemetryPayload, externalPDPUrl: externalPDPUrl.toString() },
        onTelemetrySend: () => {
          accommodationPlaceReplaceUrl(externalPDPUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Try redirect to joy native
     */
    if (hotelId && idType === HotelIdType.joy && travelHotelLinkRedirectEnabled) {
      // @todo - Add redirect to joy native hotel
    }

    /**
     * Try redirect to ZentrumHub white label
     */
    if (shouldSendUsersToZentrumHubWhiteLabel && hotelId && isValid && ((travelHotelLinkRedirectEnabled && idType === HotelIdType.hotels) || !isValidHotelIdType)) {
      const zhUrl = new URL(`${config.zentrumhubWhitelableBaseUrl}/${hotelId}`);
      const campaignId = `${eventInfo?.eventId}_${anonymousId ?? ''}`;
      zhUrl.searchParams.append('campaignid', campaignId || '');

      if (isCheckInOrCheckoutValid) {
        zhUrl.searchParams.append('checkin', checkIn);
        zhUrl.searchParams.append('checkout', checkOut);
      }

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'zentrumHub',
        destinationUrl: zhUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          accommodationPlaceReplaceUrl(zhUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try to redirect to hotel website
     */
    let hotelWebsiteUrl: URL | undefined;
    if (hotelWebsite) {
      try {
        hotelWebsiteUrl = new URL(ensureHttpsPrefix(hotelWebsite));
      } catch {
        // Do nothing
      }
    }
    if (hotelWebsite && hotelWebsiteUrl) {
      const hotelWebsiteWithRedirectService = guestLinkUseRedirectService
        ? queryString.stringifyUrl({
            url: `${config.redirectServiceUri}/redirect`,
            query: {
              url: hotelWebsite
            }
          })
        : hotelWebsite;
      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'hotelWebsite',
        destinationUrl: hotelWebsiteUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          if (accommodationsGuestSafeLinkEnabled) {
            setSafeLink(hotelWebsite);
          } else {
            accommodationPlaceReplaceUrl(hotelWebsiteWithRedirectService);
          }
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try redirect to Google Maps
     */
    const googleUrlString = generateGoogleUrl({
      placeId: gplid,
      name,
      address,
      lat,
      lng
    });
    if (googleUrlString) {
      const googleUrl = new URL(googleUrlString);

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'googleMaps',
        destinationUrl: googleUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          if (accommodationsGuestSafeLinkEnabled) {
            setSafeLink(googleUrl.toString());
          } else {
            accommodationPlaceReplaceUrl(googleUrl.toString());
          }
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, show not found page
     */
    telemetry.guestPartnerHotelBookingRedirect({
      destinationName: 'notFound',
      destinationUrl: new URL(window.location.href),
      ...telemetryPayload
    });

    setShowNotFound(true);
  }, [
    accommodationsGuestSafeLinkEnabled,
    checkIn,
    checkOut,
    data,
    eventInfo,
    eventInfoLoading,
    featureFlagsLoading,
    getStringQueryParam,
    guestLinkUseRedirectService,
    isCheckInOrCheckoutValid,
    isPartnerHotelValidQueryLoading,
    redirectInProgress,
    shouldSendUsersToZentrumHubWhiteLabel,
    telemetry,
    hotelId,
    travelHotelLinkRedirectLoading,
    travelHotelLinkRedirectEnabled,
    isZhId,
    idType,
    isValidHotelIdType,
    anonymousId,
    checkInCheckOutMessage
  ]);

  return showNotFound ? (
    <PageNotFound />
  ) : (
    <>
      {!redirectInProgress.current && <JoyLogoLoader loaderKey="guest-hotel-booking-partner" />}
      <RedirectWithSafeLink
        href={safeLink}
        skipTelemetry
        showEmptyTabMessage
        target="_self"
        onClose={() => {
          setSafeLink(undefined);
        }}
      />
    </>
  );
};
