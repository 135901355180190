import { AccommodationHotelsQuery, AccommodationPlaceType, ReservedRoomBlockStatus } from '@graphql/generated';
import { AccommodationItemList, TravelMapBlockType } from '../TravelMap.types';
import { CustomPropertyFormFields } from '../pages/CustomProperty/CustomPropertyForm.types';
import { HotelFormFields } from '../pages/Hotel/HotelForm.types';
import { LocationInputV2Response } from '@shared/components/LocationInputV2/LocationInputV2.types';
import { Location } from '@shared/components/LocationInput/LocationInput.controller';
import { PreDefinedGooglePlace } from '@shared/utils/hooks/usePreDefinedGooglePlaces';
import { PlaceInfoAdvanced } from '@shared/core/places/places';
import { RoomBlockFormFields } from '../pages/RoomBlock/RoomBlockForm.types';

interface DetailAddressArgs {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export const getHotelAddress = ({ address, city, state, postalCode, country }: DetailAddressArgs) => {
  const fullAddress = `${address ?? ''}${city ? ', ' + city : ''}${state || postalCode ? ', ' : ''}${state ?? ''}${postalCode ? ' ' + postalCode : ''}${
    country ? ', ' + country : ''
  }`;
  return fullAddress;
};

export const generateAccommodationList: (accommodation: AccommodationHotelsQuery['eventByName']) => AccommodationItemList[] = accommodation => {
  return [
    ...(accommodation?.hotelRoomBlocks?.reservedRoomBlocks?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active) || []).map(roomblock => ({
      id: roomblock.id,
      type: TravelMapBlockType.travelMapAccommodationsRoomblock,
      isFavorite: roomblock.isFavorite,
      photoUrl: roomblock.photo?.url || '',
      address: roomblock.address || '',
      cutoffDate: roomblock.cutoffDate || '',
      displayName: roomblock.displayName || '',
      status: roomblock.status,
      isPhotoHidden: false,
      lat: roomblock.latitude,
      lng: roomblock.longitude,
      rating: roomblock.starRating,
      description: roomblock.note,
      price: roomblock.pricePerNight,
      strikeoutPrice: roomblock.strikeoutPricePerNight,
      websiteUrl: roomblock.hotelMoreInfoUrl ?? undefined
    })),
    ...(accommodation?.accommodationPlacesV2 || []).map(accPlaceV2 => ({
      id: accPlaceV2.id,
      type: accPlaceV2.type === AccommodationPlaceType.hotel ? TravelMapBlockType.travelMapAccommodationsHotel : TravelMapBlockType.travelMapAccommodationsCustom,
      isFavorite: false,
      photoUrl: accPlaceV2.customPhoto?.url || accPlaceV2.photo?.url || '',
      isPhotoHidden: !!accPlaceV2.isPhotoHidden,
      address:
        accPlaceV2.customAddress ||
        getHotelAddress({ address: accPlaceV2.address, city: accPlaceV2.city, state: accPlaceV2.state, postalCode: accPlaceV2.postalCode, country: accPlaceV2.country }) ||
        '',
      cutoffDate: '',
      displayName: accPlaceV2.customName || accPlaceV2.name || '',
      lat: accPlaceV2.lat,
      lng: accPlaceV2.long,
      description: accPlaceV2.description,
      phone: accPlaceV2.phone,
      placeId: accPlaceV2.zhId || accPlaceV2.googlePlaceId,
      websiteUrl: accPlaceV2.customUrl || accPlaceV2.url || undefined
    }))
  ];
};

/**
 * Reset form fields when change hotel from autocomplete
 */
export const resetHotelFormFields = (updateFormValues: CustomPropertyFormFields | HotelFormFields, isHotelForm?: boolean) => {
  updateFormValues.customPhotoId = null;
  updateFormValues.photoUrl = null;
  updateFormValues.phone = '';
  updateFormValues.customPhone = '';
  updateFormValues.url = null;
  updateFormValues.customUrl = null;
  updateFormValues.postalCode = '';
  updateFormValues.zhId = '';
  if (isHotelForm) {
    updateFormValues.address = '';
  }
};

/**
 * Get place details from google place and align with hotel form
 */
export const updateHotelFormDataFromGooglePlace = (
  updateFormValues: CustomPropertyFormFields | HotelFormFields,
  location: LocationInputV2Response | Location,
  isHotelForm?: boolean
) => {
  updateFormValues.placeId = location.placeId;
  updateFormValues.googlePlaceId = location.placeId;
  updateFormValues.country = location.country;
  updateFormValues.latitude = location.lat;
  updateFormValues.longitude = location.lng;
  if ('address' in location && location.address && isHotelForm) {
    updateFormValues.location = location.address;
  }
};

/**
 * Get place details from pre-defined place and align with hotel form
 */
export const updateHotelFormDataFromPreDefinedPlace = (updateFormValues: CustomPropertyFormFields | HotelFormFields, preDefinedPlace: PreDefinedGooglePlace) => {
  updateFormValues = { ...updateFormValues, ...preDefinedPlace };
  updateFormValues.customName = preDefinedPlace.name;
  updateFormValues.customPhone = preDefinedPlace.phone;
  updateFormValues.customAddress = preDefinedPlace.formattedAddress || preDefinedPlace.address;
  updateFormValues.googlePlaceId = preDefinedPlace.placeId;
  updateFormValues.location = preDefinedPlace.address;
  updateFormValues.latitude = preDefinedPlace.lat;
  updateFormValues.longitude = preDefinedPlace.lng;
};

/**
 * Get place details from zh and align with hotel form
 */
export const updateHotelFormDataFromZhPlace = (updateFormValues: CustomPropertyFormFields | HotelFormFields, place: PlaceInfoAdvanced) => {
  updateFormValues.address = place.formattedAddress ?? '';
  updateFormValues.customAddress = place.formattedAddress ?? '';
  updateFormValues.postalCode = place.addressComponents?.find(component => component.types?.includes('postal_code'))?.longText ?? '';
  updateFormValues.phone = place.internationalPhoneNumber ?? '';
  updateFormValues.customPhone = place.internationalPhoneNumber ?? '';
};

/**
 * Get place details from pre-defined place and align with room block form
 */
export const updateRoomBlockFormDataFromPreDefinedPlace = (updateFormValues: RoomBlockFormFields, preDefinedPlace: PreDefinedGooglePlace) => {
  updateFormValues.hotelMoreInfoUrl = preDefinedPlace.url;
  updateFormValues.starRating = preDefinedPlace.rating;
  updateFormValues.postalCode = preDefinedPlace.postalCode;
  updateFormValues.address = preDefinedPlace.address;
  updateFormValues.latitude = preDefinedPlace.lat;
  updateFormValues.longitude = preDefinedPlace.lng;
};

/**
 * Get place details from google place and align with room block form
 */
export const updateRoomBlockFormDataFromGooglePlace = (updateFormValues: RoomBlockFormFields, place: PlaceInfoAdvanced, location: LocationInputV2Response) => {
  updateFormValues.locationValue = place.displayName.text || location.displayName || '';
  updateFormValues.hotelMoreInfoUrl = place.websiteUri ?? '';
  updateFormValues.starRating = place.rating ?? '';
  updateFormValues.postalCode = place.addressComponents?.find(component => component.types?.includes('postal_code'))?.longText ?? '';
  // Use from prefetched location
  updateFormValues.address = location.address ?? '';
  updateFormValues.latitude = location.lat ?? undefined;
  updateFormValues.longitude = location.lng ?? undefined;
};
