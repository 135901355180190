import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { TelPartnerHotelSearchRedirectParams } from './routes/HotelBookingPartnerSearch/HotelBookingPartnerSearch.types';

export type PagesForTelemetry = 'hotelbooking' | 'roomblockhotel' | 'joyPlaceFallback' | 'hotelbookingPartnerSearch';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  hotelbooking: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'hotelbooking',
    extraPageInfo: {
      role: userRole
    }
  }),
  joyPlaceFallback: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'joyPlaceFallback',
    extraPageInfo: {
      role: userRole
    }
  }),
  roomblockhotel: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'roomblockhotel',
    extraPageInfo: {
      role: userRole
    }
  }),
  hotelbookingPartnerSearch: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'hotelbookingPartnerSearch',
    extraPageInfo: {
      role: userRole
    }
  })
};

export interface GuestPartnerHotelBookingRedirectParams {
  source?: string;
  providerActiveFlag?: 'joynativecheckout' | 'externalpdpurl' | 'zh';
  destinationName: string;
  destinationUrl: URL;
  externalPDPUrl?: string | null;
  zentrumHub?: {
    id: string;
    isValid?: boolean;
    hasRates?: boolean;
    hasInventory?: boolean;
  };
  hotelWebsite?: {
    value: string;
  };
  googleMaps?: {
    gplid?: string;
    name?: string;
    address?: string;
    lat?: string;
    lng?: string;
  };
  onTelemetrySend?: () => void;
}

const hotelBookingGuestSiteTelemetry = createTelemetryObject({
  actions: {
    guestPartnerHotelBookingRedirect: ({
      destinationName,
      destinationUrl,
      externalPDPUrl,
      zentrumHub,
      hotelWebsite,
      googleMaps,
      providerActiveFlag,
      source,
      onTelemetrySend
    }: GuestPartnerHotelBookingRedirectParams) => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestPartnerHotelBookingRedirect',
        destinationName,
        destinationURL: destinationUrl?.toString(),
        destinationDomain: destinationUrl?.hostname,
        success: destinationName != 'notFound',
        externalPDPUrl,
        zentrumHub,
        hotelWebsite,
        googleMaps,
        providerActiveFlag,
        source
      },
      callback: onTelemetrySend
    }),
    partnerHotelSearchRedirect: ({ destinationName, destinationUrl, onTelemetrySend, ...args }: TelPartnerHotelSearchRedirectParams) => ({
      action: 'PartnerHotelSearchRedirect',
      category: 'travel',
      label: 'hotelSearch',
      extraInfo: {
        name: 'partnerHotelSearchRedirect',
        destinationName,
        destinationURL: destinationUrl?.toString(),
        destinationDomain: destinationUrl?.hostname,
        ...args
      },
      callback: onTelemetrySend
    }),
    guestHotelBookingRedirect: (destinationURL: string, destinationDomain: string, success: boolean) => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestHotelBookingRedirect',
        destinationURL: destinationURL || '',
        destinationDomain: destinationDomain || '',
        success
      }
    }),
    guestHotelBookingQueryError: () => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestHotelBookingRedirectQueryError'
      }
    }),
    guestRoomblockHotelRedirect: (destinationURL: string, destinationDomain: string, success: boolean, callback: () => void) => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'roomblockhotel',
      extraInfo: {
        name: 'guestRoomblockHotelRedirect',
        destinationURL: destinationURL || '',
        destinationDomain: destinationDomain || '',
        success
      },
      callback
    }),
    guestRoomblockHotelQueryError: () => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'roomblockhotel',
      extraInfo: {
        name: 'guestRoomblockHotelQueryError'
      }
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry: useHotelBookingGuestSiteTelemetry } = createTelemetry(hotelBookingGuestSiteTelemetry);
export { TelemetryProvider, useHotelBookingGuestSiteTelemetry };
