import { DesignLayoutType, EventPageFragment, EventPageType } from '@graphql/generated';
import { GuestSiteEvent, GuestSiteEventDesign } from './layout.types';
import { darkenColor, isLightColor } from '@shared/utils/color';
import { toRgbArray } from '@shared/utils/color';
import { ButtonV2Props, defaultTheme } from '@withjoy/joykit';
import chroma from 'chroma-js';
const nonSupportedEventPageSet = new Set([EventPageType.guests, EventPageType.tidbits]);
export const isSupportedEventPage = (pageType: EventPageType) => {
  return !nonSupportedEventPageSet.has(pageType);
};

export const findEventPageByPageSlug = (pages: ReadonlyArray<EventPageFragment>, target: string, isCustomPage?: boolean): Readonly<EventPageFragment> | undefined => {
  return pages.find(
    page => isSupportedEventPage(page.type) && page.pageSlug.toLocaleLowerCase() === target.toLocaleLowerCase() && (!isCustomPage || page.type === EventPageType.custom)
  );
};

export const findEventPageByType = (pages: ReadonlyArray<EventPageFragment>, target: EventPageType): Readonly<EventPageFragment> | undefined => {
  return pages.find(page => isSupportedEventPage(page.type) && page.type === target);
};

export const shouldRenderGraphicAccent = (accent: Maybe<string>) => !!accent && accent !== 'blank';

export const getEventMenuPropsFromEvent = (event: GuestSiteEvent) => {
  return {
    eventId: event?.id || '',
    layoutType: event?.eventDesign?.websiteLayout.layoutType || DesignLayoutType.aloha,
    dateInMilliseconds: event.info?.dateV0918?.milliseconds || 0,
    pages: event?.pages || []
  };
};

export const getButtonPropsBasedOnBackground = (backgroundColor: string) => {
  const buttonProps: Partial<ButtonV2Props> = {};
  const { colors } = defaultTheme;

  if (backgroundColor) {
    buttonProps.size = 'lg';
    buttonProps.border = `1px soild ${colors.gray5}`;
    buttonProps.shape = 'rounded';

    if (isLightColor(...toRgbArray(backgroundColor))) {
      buttonProps.variant = 'solid';
      buttonProps.backgroundColor = colors.black;
      buttonProps._hover = {
        backgroundColor: colors.black,
        color: colors.white
      };
    } else {
      buttonProps.variant = 'outline';
      buttonProps.backgroundColor = colors.white;
      buttonProps.color = colors.black;
      buttonProps._hover = {
        backgroundColor: colors.white,
        color: colors.black
      };
    }
  }

  return buttonProps;
};

export const getFooterLinkColor = (color: string) => {
  const [r, g, b] = chroma(color).rgb();
  return isLightColor(r, g, b) ? 'black' : 'white';
};

export const getHoverColor = (color: string) => {
  const darkenedColor = darkenColor(color);
  return chroma(darkenedColor).brighten(0.2).hex();
};

export const areDefaultColorsOverridden = (eventDesign: GuestSiteEventDesign) => {
  // Custom colors are applied for blank theme on brannan layout irrespective of default color preference
  return eventDesign?.theme?.themeId === 'blank' && eventDesign?.websiteLayout?.layoutType === DesignLayoutType.brannan;
};
