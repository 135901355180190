import { useMemo } from 'react';
import { AccommodationCombined, AccommodationCombinedType } from '../components/Accommodations/Accommodation.types';
import { withWindow } from '@shared/utils/withWindow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCustomPageTelemetry } from '../../../Custom.telemetry';
import { useTranslation } from '@shared/core';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { useIncrementInterestedGuestCountMutation } from '@graphql/generated';
import { useAccommodationPlaceRoutePaths } from '@apps/guest/routes/HotelBooking/routes/AccommodationPlacePartner/AccommodationPlacePartner.utils';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useHotelBookingGuestSiteTelemetry } from '@apps/guest/routes/HotelBooking/HotelBooking.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { EventVenue } from '@shared/utils/eventPlace';

interface AccommodationsCtaArgs {
  eventHandle: string;
  accommodation: AccommodationCombined;
  locationInfo?: EventVenue;
}

export const useAccommodationsCta = (props: AccommodationsCtaArgs) => {
  const { eventHandle, accommodation, locationInfo } = props;
  const {
    displayName,
    type,
    pricePerNight,
    isFavorite,
    strikeoutPricePerNight,
    id,
    interestedGuestCount,
    latitude,
    longitude,
    url,
    customUrl,
    isFromAccommodationCatalog
  } = accommodation;

  const { eventInfo } = useEventInfo();
  const telemetry = useCustomPageTelemetry();
  const hotelBookingTel = useHotelBookingGuestSiteTelemetry();
  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const [incrementInterestedGuestCount] = useIncrementInterestedGuestCountMutation();
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  const { accommodationPlace } = useAccommodationPlaceRoutePaths();
  const { loading: accommodationsGuestHotelProviderLoading, value: accommodationsGuestHotelProvider } = useFeatureValue('accommodationsGuestHotelProviderEnabled');

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const isJoyNativeCheckoutEnabled = useMemo(
    () => !accommodationsGuestHotelProviderLoading && accommodationsGuestHotelProvider === 'joynativecheckout' && isFromAccommodationCatalog && !customUrl,
    [accommodationsGuestHotelProvider, accommodationsGuestHotelProviderLoading, customUrl, isFromAccommodationCatalog]
  );

  const { ctaTitle } = useMemo(() => {
    const buttonCta = {
      [AccommodationCombinedType.Custom]: accTrans.selectCTACustom(),
      [AccommodationCombinedType.Hotel]: accTrans.selectCTAHotel(),
      [AccommodationCombinedType.Roomblock]: accTrans.selectCTARoomBlock()
    };
    return {
      ctaTitle: buttonCta[type]
    };
  }, [type, accTrans]);

  const ctaUrl = useMemo(() => {
    const redirectUrl = withWindow(global => {
      let accommodationUrl = accommodationPlace.goToPath(eventHandle, id);
      if (type === AccommodationCombinedType.Roomblock) {
        accommodationUrl = `${global.location.origin}${buildPath(eventHandle, roomblock, id)}`;
      } else if (isJoyNativeCheckoutEnabled) {
        accommodationUrl = `${global.location.origin}/${eventHandle}/accommodations/${id}`;
      }
      return accommodationUrl;
    }, accommodationPlace.goToPath(eventHandle, id));
    return redirectUrl;
  }, [accommodationPlace, buildPath, eventHandle, id, isJoyNativeCheckoutEnabled, roomblock, type]);

  const telemetryHotelInfo = {
    hotelName: displayName || '',
    isHotelPreferred: isFavorite || false,
    hotelPrice: pricePerNight || undefined,
    hotelStrikeThroughPrice: strikeoutPricePerNight || undefined,
    accommodationsType: type
  };

  const handleOnSelectAccommodation = useEventCallback(() => {
    if (type === AccommodationCombinedType.Roomblock) {
      handleOnSelectRoomBlock();
      return;
    }
    handleOnSelectHotel();
  });

  const handleOnSelectHotel = useEventCallback(() => {
    const location = { lat: locationInfo?.latitude || eventInfo?.locationInfo?.latitude, lng: locationInfo?.longitude || eventInfo?.locationInfo?.longitude };
    const haveDistanceToVenue = Boolean(latitude && longitude && location?.lat && location.lng);
    const venueName = locationInfo?.name ?? eventInfo?.locationInfo?.name ?? '';
    const distance = distanceInMiles(latitude || 0, longitude || 0, location?.lat || 0, location?.lng || 0).toFixed(1);
    const hotel = { eventHandle, accommodationPlaceId: id, hotelName: displayName || '', venueName, ...(haveDistanceToVenue && { milesFromVenue: distance }) };
    if (isJoyNativeCheckoutEnabled) {
      hotelBookingTel.guestPartnerHotelBookingRedirect({
        destinationName: 'joynativecheckout',
        destinationUrl: new URL(ctaUrl),
        providerActiveFlag: accommodationsGuestHotelProvider,
        hotelWebsite: url ? { value: url } : undefined
      });
    }
    telemetry.viewHotelPDPPage(hotel);
    telemetry.accommodationsLinkInteracted(accommodationPlace.goToPath(eventHandle, id), telemetryHotelInfo);
  });

  const handleOnSelectRoomBlock = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      incrementInterestedGuestCount({
        variables: { reservedRoomBlockId: id }
      });
      const telemetryHotelInfoWithInterestCountIfAvailable = {
        ...telemetryHotelInfo,
        displayedInterestedGuestCount: interestedGuestCount || 0
      };
      telemetry.accommodationsLinkInteracted(buildPath(eventHandle, roomblock, id), telemetryHotelInfoWithInterestCountIfAvailable);
    }
  });

  return {
    ctaTitle,
    ctaUrl,
    handleOnSelectAccommodation,
    enabledAdminGuestSiteBannerDialog
  };
};
