import { useFeatureValue } from '@shared/core/featureFlags';
import { withWindow } from '@shared/utils/withWindow';

export interface GeneratePartnerLinkParams {
  eventId?: string;
  googleId?: string;
  zhId?: string;
  hotelWebsite?: string;
  location?: { lat: number; lng: number };
  name?: string;
  address?: string;
}

export enum PartnerLinkSearchParams {
  googlePlaceId = 'gplid',
  hotelWebsite = 'hotelWebsite',
  latitude = 'lat',
  longitude = 'lng',
  name = 'name',
  address = 'address',
  idType = 'idType',
  source = 'source'
}

export const useHotelBookingPartnerLink = () => {
  const zentrumHubEnabled = useFeatureValue('zentrumHubEnabled').value === 'on';
  const zentrumHubProviderExperiment = useFeatureValue('zentrumHubPartnerEnabled', {
    skip: !zentrumHubEnabled
  }).value;

  const generateUrlPartnerLink = ({ eventId, address, googleId, hotelWebsite, location, name, zhId }: GeneratePartnerLinkParams): string | undefined => {
    if (!eventId) {
      return;
    }

    return withWindow<string | undefined>(window => {
      const destination = new URL(window.location.origin);
      destination.pathname = `/hotel/${zhId || ''}`;

      // Add hotel website
      if (hotelWebsite) {
        destination.searchParams.set(PartnerLinkSearchParams.hotelWebsite, hotelWebsite);
      }

      // Add Google props
      if (googleId) {
        destination.searchParams.set(PartnerLinkSearchParams.googlePlaceId, googleId);
      } else if (name && address) {
        destination.searchParams.set(PartnerLinkSearchParams.name, name);
        destination.searchParams.set(PartnerLinkSearchParams.address, address);
      } else if (location) {
        destination.searchParams.set(PartnerLinkSearchParams.latitude, location.lat.toString());
        destination.searchParams.set(PartnerLinkSearchParams.longitude, location.lng.toString());
      }

      const url = new URL(window.location.origin);
      url.pathname = `/eventredirect/${eventId}`;
      url.searchParams.set('destination', destination.pathname + destination.search);
      return url.toString();
    }, undefined);
  };

  const generateHotelId = (googleId?: string, zhId?: string) => {
    return zentrumHubProviderExperiment === 'on' ? `g-${googleId}&z-${zhId}` : googleId || '';
  };

  return { generateUrlPartnerLink, generateHotelId };
};
