import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { GuestPartnerHotelBookingRedirectParams, useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useEventInfo } from '@shared/utils/eventInfo';
import { HotelProvider, useAccommodationPlaceV2ByIdQuery, useIsPartnerHotelValidLazyQuery } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { accommodationPlaceReplaceUrl } from './AccommodationPlacePartner.utils';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import queryString from 'query-string';
import { config } from '@static/js/env.config';
import { AnalyticsContext } from '@shared/core';
import { useCheckInCheckoutDates } from '../../hooks/useCheckInCheckoutDates';
import { ensureHttpsPrefix } from '@shared/utils/urlHelpers';

/**
 * Handles the accommodation place partner redirect
 *
 * @param {Object} props - The props object for the component.
 * @param {string} props.accommodationPlaceV2ByIdId - The ID of the accommodation place.
 * @returns {JSX.Element} The component's JSX. - Redirect to partner hotel or hotel or 404.
 */
export const AccommodationPlacePartnerV2 = ({ accommodationPlaceV2ByIdId }: { accommodationPlaceV2ByIdId: string }) => {
  const redirectInProgress = useRef(false);
  const [safeLink, setSafeLink] = useState<string | undefined>();
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo, loading: eventInfoLoading } = useEventInfo();
  const eventDate = eventInfo?.finalizedDate?.dateString || undefined;
  const { anonymousId } = useContext(AnalyticsContext);

  const { checkIn, checkOut, isCheckInOrCheckoutValid, message: checkInCheckOutMessage } = useCheckInCheckoutDates({ eventDate });

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const accommodationsGuestSafeLinkEnabled = useFeatureValue('accommodationsGuestSafeLinkEnabled').value === 'on';
  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';

  const [fetchIsPartnerHotelValidQuery, { data, loading: isPartnerHotelValidQueryLoading }] = useIsPartnerHotelValidLazyQuery({ batchMode: 'off' });

  const { data: accommodationsPlaceV2Data, loading: isAccommodationsPlaceV2Loading } = useAccommodationPlaceV2ByIdQuery({
    variables: {
      accommodationPlaceV2ByIdId: accommodationPlaceV2ByIdId ?? ''
    },
    batchMode: 'off',
    skip: !accommodationPlaceV2ByIdId,
    onCompleted: response => {
      const hotelIdAsNumber = parseInt(response.accommodationPlaceV2ById?.zhId || '', 10);
      if (response.accommodationPlaceV2ById?.zhId && !isNaN(hotelIdAsNumber)) {
        fetchIsPartnerHotelValidQuery({
          variables: {
            payload: {
              zhId: hotelIdAsNumber,
              checkIn,
              checkOut
            }
          }
        });
      }
    }
  });

  const queryParams = useQueryParams();
  const getStringQueryParam = useEventCallback((key: string) => {
    const value = queryParams[key];
    return Array.isArray(value) ? value[0] : value ?? undefined;
  });

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (isAccommodationsPlaceV2Loading || redirectInProgress.current || eventInfoLoading || isPartnerHotelValidQueryLoading) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(checkInCheckOutMessage);

    // Get data from api
    const { url: hotelWebsite, lat, long: lng, googlePlaceId: gplid, name, address, zhId: zentrumHubId, externalPDP } = accommodationsPlaceV2Data?.accommodationPlaceV2ById || {};
    const { isValid, hasRates, hasInventory } = data?.zentrumHubHotelIsValid || {};

    // Build destination name for telemetry - Don't want break existing telemetry
    let destinationName: string = externalPDP?.provider ?? 'notFound';
    if (externalPDP?.provider === HotelProvider.zh) {
      destinationName = 'zentrumHub';
    } else if (externalPDP?.provider === HotelProvider.nuitee) {
      destinationName = 'externalPDPUrl';
    } else if (externalPDP?.provider === HotelProvider.googlePlaceUrl) {
      destinationName = 'googleMaps';
    } else if (externalPDP?.provider === HotelProvider.hotelWebsiteUrl || externalPDP?.provider === HotelProvider.customUrl) {
      destinationName = 'hotelWebsite';
    }

    // Build telemetry payload -  Don't want break existing telemetry
    const externalPDPUrl = externalPDP?.provider === HotelProvider.nuitee ? externalPDP?.url : undefined;
    const telemetryPayload: Pick<GuestPartnerHotelBookingRedirectParams, 'destinationName' | 'externalPDPUrl' | 'zentrumHub' | 'hotelWebsite' | 'googleMaps'> = {
      destinationName,
      externalPDPUrl,
      zentrumHub: zentrumHubId ? { id: zentrumHubId, isValid, hasRates, hasInventory } : undefined,
      hotelWebsite: hotelWebsite ? { value: hotelWebsite } : undefined,
      googleMaps:
        gplid || lat || lng || name || address
          ? {
              gplid: gplid ?? undefined,
              name: name ?? undefined,
              address: address ?? undefined,
              lat: lat?.toString(),
              lng: lng?.toString()
            }
          : undefined
    };

    // Check for custom url and hotel website url, it need to pass through redirect service.
    if ((externalPDP?.provider === HotelProvider.customUrl || externalPDP?.provider === HotelProvider.hotelWebsiteUrl) && externalPDP?.url) {
      const hotelWebsiteUrl = new URL(ensureHttpsPrefix(externalPDP.url));
      let hotelWebsiteWithRedirectService = externalPDP.url;
      const redirectServicePath = `/eventredirect/`;
      if (guestLinkUseRedirectService && !externalPDP.url.includes(redirectServicePath)) {
        // Every link should pass through redirect service, some accommodation places have hotel link instead of eventredirect link
        hotelWebsiteWithRedirectService = queryString.stringifyUrl({ url: `${config.redirectServiceUri}/redirect`, query: { url: externalPDP.url } });
      }
      telemetry.guestPartnerHotelBookingRedirect({
        destinationUrl: hotelWebsiteUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          if (accommodationsGuestSafeLinkEnabled) {
            setSafeLink(externalPDP.url);
          } else {
            accommodationPlaceReplaceUrl(hotelWebsiteWithRedirectService);
          }
        }
      });

      redirectInProgress.current = true;
      return;

      // Rest all provider redirect logic
    } else if (externalPDP?.url) {
      const redirectUrl = new URL(externalPDP.url);

      telemetry.guestPartnerHotelBookingRedirect({
        destinationUrl: redirectUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          accommodationPlaceReplaceUrl(redirectUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;

      // Show 404 if not find any valid redirect url
    } else {
      telemetry.guestPartnerHotelBookingRedirect({
        destinationUrl: new URL(window.location.href),
        ...telemetryPayload
      });

      setShowNotFound(true);
    }
  }, [
    accommodationsGuestSafeLinkEnabled,
    accommodationsPlaceV2Data,
    checkIn,
    checkOut,
    data,
    eventInfo,
    eventInfoLoading,
    getStringQueryParam,
    guestLinkUseRedirectService,
    isAccommodationsPlaceV2Loading,
    isCheckInOrCheckoutValid,
    isPartnerHotelValidQueryLoading,
    redirectInProgress,
    telemetry,
    anonymousId,
    checkInCheckOutMessage
  ]);

  return showNotFound ? (
    <PageNotFound />
  ) : (
    <>
      {!redirectInProgress.current && <JoyLogoLoader loaderKey="guest-hotel-booking-accommodations-place" />}
      <RedirectWithSafeLink
        href={safeLink}
        skipTelemetry
        showEmptyTabMessage
        target="_self"
        onClose={() => {
          setSafeLink(undefined);
        }}
      />
    </>
  );
};
