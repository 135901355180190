import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { GuestPartnerHotelBookingRedirectParams, useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useAccommodationPlaceV2ByIdQuery, useIsPartnerHotelValidLazyQuery } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { accommodationPlaceReplaceUrl } from './AccommodationPlacePartner.utils';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import queryString from 'query-string';
import { config } from '@static/js/env.config';
import { AnalyticsContext } from '@shared/core';
import { useCheckInCheckoutDates } from '../../hooks/useCheckInCheckoutDates';

export const AccommodationPlacePartner: React.FC<{ accommodationPlaceV2ByIdId?: string }> = ({ accommodationPlaceV2ByIdId }) => {
  const redirectInProgress = useRef(false);
  const [safeLink, setSafeLink] = useState<string | undefined>();
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo, loading: eventInfoLoading } = useEventInfo();
  const eventDate = eventInfo?.finalizedDate?.dateString || undefined;
  const { anonymousId } = useContext(AnalyticsContext);

  const { checkIn, checkOut, isCheckInOrCheckoutValid, message: checkInCheckOutMessage } = useCheckInCheckoutDates({ eventDate });

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const accommodationsGuestSafeLinkEnabled = useFeatureValue('accommodationsGuestSafeLinkEnabled').value === 'on';

  const { value: accommodationsGuestHotelProvider, loading: accommodationsGuestHotelProviderLoading } = useFeatureValue('accommodationsGuestHotelProviderEnabled');

  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';
  const { value: zentrumHubEnabled, loading: featureFlagsLoading } = useFeatureValue('zentrumHubEnabled');
  const shouldSendUsersToZentrumHubWhiteLabel =
    useFeatureValue('zentrumHubPartnerEnabled', {
      skip: zentrumHubEnabled !== 'on'
    }).value === 'on';

  const [fetchIsPartnerHotelValidQuery, { data, loading: isPartnerHotelValidQueryLoading }] = useIsPartnerHotelValidLazyQuery({ batchMode: 'off' });

  const { data: accommodationsPlaceV2Data, loading: isAccommodationsPlaceV2Loading } = useAccommodationPlaceV2ByIdQuery({
    variables: {
      accommodationPlaceV2ByIdId: accommodationPlaceV2ByIdId ?? ''
    },
    batchMode: 'off',
    skip: !accommodationPlaceV2ByIdId,
    onCompleted: response => {
      const hotelIdAsNumber = parseInt(response.accommodationPlaceV2ById?.zhId || '', 10);
      if (response.accommodationPlaceV2ById?.zhId && !isNaN(hotelIdAsNumber)) {
        fetchIsPartnerHotelValidQuery({
          variables: {
            payload: {
              zhId: hotelIdAsNumber,
              checkIn,
              checkOut
            }
          }
        });
      }
    }
  });

  const queryParams = useQueryParams();
  const getStringQueryParam = useEventCallback((key: string) => {
    const value = queryParams[key];
    return Array.isArray(value) ? value[0] : value ?? undefined;
  });

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (
      isAccommodationsPlaceV2Loading ||
      redirectInProgress.current ||
      eventInfoLoading ||
      featureFlagsLoading ||
      (shouldSendUsersToZentrumHubWhiteLabel && isPartnerHotelValidQueryLoading) ||
      accommodationsGuestHotelProviderLoading
    ) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(checkInCheckOutMessage);

    const { url: hotelWebsite, lat, long: lng, googlePlaceId: gplid, name, address, zhId: zentrumHubId, customUrl, externalPDPUrl } =
      accommodationsPlaceV2Data?.accommodationPlaceV2ById || {};
    const { isValid, hasRates, hasInventory } = data?.zentrumHubHotelIsValid || {};
    const telemetryPayload: Pick<GuestPartnerHotelBookingRedirectParams, 'providerActiveFlag' | 'externalPDPUrl' | 'zentrumHub' | 'hotelWebsite' | 'googleMaps'> = {
      providerActiveFlag: accommodationsGuestHotelProvider,
      externalPDPUrl,
      zentrumHub: zentrumHubId ? { id: zentrumHubId, isValid, hasRates, hasInventory } : undefined,
      hotelWebsite: hotelWebsite ? { value: hotelWebsite } : undefined,
      googleMaps:
        gplid || lat || lng || name || address
          ? {
              gplid: gplid ?? undefined,
              name: name ?? undefined,
              address: address ?? undefined,
              lat: lat?.toString(),
              lng: lng?.toString()
            }
          : undefined
    };

    /**
     * Try redirect to Nuitee
     */
    if (accommodationsGuestHotelProvider === 'externalpdpurl' && externalPDPUrl && !customUrl) {
      const externalPDPRedirectUrl = new URL(externalPDPUrl);
      const clientReference = `${eventInfo?.eventId}_${anonymousId ?? ''}`;
      externalPDPRedirectUrl.searchParams.append('clientReference', clientReference || '');
      externalPDPRedirectUrl.searchParams.append('checkin', checkIn);
      externalPDPRedirectUrl.searchParams.append('checkout', checkOut);
      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'externalPDPUrl',
        destinationUrl: externalPDPRedirectUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          accommodationPlaceReplaceUrl(externalPDPRedirectUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Try redirect to ZentrumHub white label
     */
    if (shouldSendUsersToZentrumHubWhiteLabel && zentrumHubId && isValid && !customUrl) {
      const zhUrl = new URL(`${config.zentrumhubWhitelableBaseUrl}/${zentrumHubId}`);
      const campaignId = `${eventInfo?.eventId}_${anonymousId ?? ''}`;
      zhUrl.searchParams.append('campaignid', campaignId || '');

      if (isCheckInOrCheckoutValid) {
        zhUrl.searchParams.append('checkin', checkIn);
        zhUrl.searchParams.append('checkout', checkOut);
      }

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'zentrumHub',
        destinationUrl: zhUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          accommodationPlaceReplaceUrl(zhUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try to redirect to hotel website
     */
    let hotelWebsiteUrl: URL | undefined;
    const url = customUrl && customUrl !== '<empty>' ? customUrl : hotelWebsite;
    if (url) {
      try {
        const completeUrl = url.startsWith('http://') || url.startsWith('https://') ? url : 'https://' + url;
        hotelWebsiteUrl = new URL(completeUrl);
      } catch (error) {
        // Do nothing
      }
    }
    if (url && hotelWebsiteUrl) {
      let hotelWebsiteWithRedirectService = url;
      const redirectServicePath = `/eventredirect/`;
      if (guestLinkUseRedirectService && !url.includes(redirectServicePath)) {
        // Every link should pass through redirect service, some accommodation places have hotel link instead of eventredirect link
        hotelWebsiteWithRedirectService = queryString.stringifyUrl({ url: `${config.redirectServiceUri}/redirect`, query: { url } });
      }
      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'hotelWebsite',
        destinationUrl: hotelWebsiteUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          if (accommodationsGuestSafeLinkEnabled) {
            setSafeLink(url);
          } else {
            accommodationPlaceReplaceUrl(hotelWebsiteWithRedirectService);
          }
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try redirect to Google Maps
     */
    const googleUrlString = generateGoogleUrl({
      placeId: gplid ?? undefined,
      name: name ?? undefined,
      address: address ?? undefined,
      lat: lat?.toString(),
      lng: lng?.toString()
    });
    if (googleUrlString) {
      const googleUrl = new URL(googleUrlString);

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'googleMaps',
        destinationUrl: googleUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          if (accommodationsGuestSafeLinkEnabled) {
            setSafeLink(googleUrl.toString());
          } else {
            accommodationPlaceReplaceUrl(googleUrl.toString());
          }
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, show not found page
     */
    telemetry.guestPartnerHotelBookingRedirect({
      destinationName: 'notFound',
      destinationUrl: new URL(window.location.href),
      ...telemetryPayload
    });

    setShowNotFound(true);
  }, [
    accommodationsGuestSafeLinkEnabled,
    accommodationsPlaceV2Data,
    checkIn,
    checkOut,
    data,
    eventInfo,
    eventInfoLoading,
    featureFlagsLoading,
    getStringQueryParam,
    guestLinkUseRedirectService,
    isAccommodationsPlaceV2Loading,
    isCheckInOrCheckoutValid,
    isPartnerHotelValidQueryLoading,
    redirectInProgress,
    shouldSendUsersToZentrumHubWhiteLabel,
    telemetry,
    accommodationsGuestHotelProvider,
    accommodationsGuestHotelProviderLoading,
    anonymousId,
    checkInCheckOutMessage
  ]);

  return showNotFound ? (
    <PageNotFound />
  ) : (
    <>
      {!redirectInProgress.current && <JoyLogoLoader loaderKey="guest-hotel-booking-accommodations-place" />}
      <RedirectWithSafeLink
        href={safeLink}
        skipTelemetry
        showEmptyTabMessage
        target="_self"
        onClose={() => {
          setSafeLink(undefined);
        }}
      />
    </>
  );
};
