import { useMemo } from 'react';
import { DEFAULT_DESTINATION, DEFAULT_RESERVED_ROOM_BLOCKS } from '../TravelMap.constants';
import { GuestTravelMapComponentFragment, ReservedRoomBlockStatus, useGuestEventHotelRoomBlocksQuery } from '@graphql/generated';
import { AccommodationPlacesV2ToAccommodationCombined, RoomBlockToAccommodationCombined } from '../components/Accommodations/Accommodation.utils';
import { sortByFavorite, sortByOrderList } from '@shared/utils/array';
import { useEventPlaces } from '@shared/utils/eventPlace';
import { isInIframe } from '@shared/utils/isInIframe';

export const useTravelMapData = (eventId: string, travelMapComponent: GuestTravelMapComponentFragment) => {
  const isIframed = isInIframe();
  const { venueInfo } = useEventPlaces(isIframed);
  const { data: accommodationPlaceData, loading } = useGuestEventHotelRoomBlocksQuery({
    variables: {
      eventId
    },
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });
  const reservedRoomBlocks = accommodationPlaceData?.event?.hotelRoomBlocks?.reservedRoomBlocks
    ?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active)
    .map(r => RoomBlockToAccommodationCombined(r));

  const eventPhoto = accommodationPlaceData?.event?.photo?.url ?? '';

  const accommodationsPlace = accommodationPlaceData?.event?.accommodationPlacesV2?.map(accommodation => AccommodationPlacesV2ToAccommodationCombined(accommodation));

  const accommodationCombined = useMemo(() => [...(reservedRoomBlocks ?? []), ...(accommodationsPlace ?? [])], [accommodationsPlace, reservedRoomBlocks]);

  const blockOrderingInitial = useMemo(() => (travelMapComponent && travelMapComponent.__typename === 'TravelMapComponent' ? travelMapComponent?.blockOrdering : []), [
    travelMapComponent
  ]);

  const sortedHotelList = useMemo(() => sortByFavorite(sortByOrderList(accommodationCombined, blockOrderingInitial ?? [])), [accommodationCombined, blockOrderingInitial]);

  const blockOrdering = blockOrderingInitial && blockOrderingInitial?.length === sortedHotelList?.length ? blockOrderingInitial : sortedHotelList?.map(roomBlock => roomBlock.id);

  const showDefaultData = !loading && (!sortedHotelList || sortedHotelList.length === 0);

  return {
    loading,
    showDefaultData,
    venueInfo,
    eventTimezone: accommodationPlaceData?.event?.info.timezone,
    sortedHotelList: sortedHotelList,
    destination: showDefaultData && !venueInfo ? DEFAULT_DESTINATION : venueInfo,
    eventPhoto,
    accommodations: showDefaultData ? DEFAULT_RESERVED_ROOM_BLOCKS : sortedHotelList,
    blockOrdering
  };
};
