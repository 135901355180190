import React, { FC } from 'react';
import { URLS } from '@shared/utils/urls';
import { BaseBoxProps } from '@withjoy/joykit';
import { getDeviceResolution } from '@shared/utils/deviceResolution';
import { GraphicAccentContainer, GraphicAccentImg } from './GraphicAccent.styles';
import { InlineEditor } from '@shared/components';
import { EventPageType } from '@graphql/generated';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';

interface GraphicAccentProps
  extends BaseBoxProps,
    Readonly<{
      accentId: string;
      id?: string;
      isInlineEditable?: boolean;
      pageName?: Maybe<EventPageType>;
      pageSlug?: string;
    }> {}

interface GraphicAccentWrapperProps {
  children: React.ReactNode;
  isInlineEditable: boolean;
  pageName: Maybe<EventPageType>;
  pageSlug: string;
  accentId: string;
}

const DEVICE_RESOLUTION = getDeviceResolution();

const GraphicAccentWrapper: FC<GraphicAccentWrapperProps> = props => {
  const { children, isInlineEditable, pageName, pageSlug, accentId } = props;

  const { t2 } = useTranslation('joykit');
  const inlineEditorTrans = t2('inlineEditor');

  const onEditClick = useEventCallback(() => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: {
        action: 'editGraphicAccent',
        inlineEditData: { accentId }
      }
    });
  });

  return isInlineEditable ? (
    <InlineEditor
      containerCSS={{ minWidth: pxToRem(200) }}
      actionData={{ edit: onEditClick }}
      wrapperType="actionOutside"
      componentName="graphicAccent"
      elementLabel={inlineEditorTrans.graphicAccent}
      pageName={pageName}
      pageSlug={pageSlug}
    >
      {children}
    </InlineEditor>
  ) : (
    <>{children}</>
  );
};

export const GraphicAccent: React.FC<GraphicAccentProps> = props => {
  const { accentId, isInlineEditable = false, pageName, pageSlug = '', ...restProps } = props;
  return (
    <GraphicAccentWrapper isInlineEditable={isInlineEditable} pageName={pageName} pageSlug={pageSlug} accentId={accentId}>
      <GraphicAccentContainer {...restProps}>
        <GraphicAccentImg src={URLS.graphicAccent(accentId)} pixelDensity={DEVICE_RESOLUTION} />
      </GraphicAccentContainer>
    </GraphicAccentWrapper>
  );
};
