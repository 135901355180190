import React, { useMemo, useState } from 'react';
import { Flex, useDisclosure } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { GuestTravelMapComponentFragment } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useTravelMapData } from './hooks/useTravelMapData';
import { ExploreOtherOptions } from './components/ExploreOtherOptions';
import { AccommodationsTileV2, AccommodationsTileV2Skeleton } from './components/AccommodationsV2/AccommodationsTileV2';
import { AccommodationsTileV2Favorite, AccommodationsTileV2FavoriteSkeleton } from './components/AccommodationsV2/AccommodationsTileV2Favorite';
import { AccommodationsDialog } from './components/AccommodationsDialog';
import { AccommodationCombined } from './components/Accommodations/Accommodation.types';
import { AccommodationsMapV2, AccommodationsMapV2Skeleton } from './components/AccommodationsMapV2';
import { useCustomPageTelemetry } from '../../Custom.telemetry';
import { JoyInteractableMapProvider } from '@shared/components/JoyInteractableMap/JoyInteractableMap.provider';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';
import { useColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';

interface TravelMapProps {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
  travelMapComponentData: GuestTravelMapComponentFragment;
}

export const TravelMapV2: React.FC<TravelMapProps> = ({ pageSlug, eventId, eventHandle, pageTitle, isBrannanLayout, travelMapComponentData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [safeLink, setSafeLink] = useState<string | undefined>();
  const [selectedAccommodation, setSelectedAccommodation] = useState<AccommodationCombined | undefined>(undefined);
  const [locationBeingHovered, setLocationBeingHovered] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isGridView = isBrannanLayout && !isMobile;
  const { backgroundColor } = useColorPaletteProvider();
  const customMapStyles = backgroundColor ? { backgroundColor: 'white', paddingTop: 10, paddingBottom: 10 } : {};
  const customMapWrapperStyles = backgroundColor ? { marginTop: 5 } : {};

  const { loading, showDefaultData, destination, eventPhoto, accommodations: accommodationsData, sortedHotelList, eventTimezone } = useTravelMapData(
    eventId,
    travelMapComponentData
  );

  const favoriteAccommodation = accommodationsData.find(roomBlock => roomBlock.isFavorite);
  const { primaryAccommodation, accommodations } = useMemo(() => {
    const primaryAccommodation = favoriteAccommodation ?? accommodationsData.length > 0 ? accommodationsData[0] : null;
    const accommodations = favoriteAccommodation ?? accommodationsData.length > 0 ? accommodationsData.slice(1) : [];
    return {
      primaryAccommodation,
      accommodations
    };
  }, [favoriteAccommodation, accommodationsData]);

  const telemetry = useCustomPageTelemetry();

  const onSelectHotel = useEventCallback((id: string, label: 'viewDetail' | 'viewPreview') => {
    const accommodation = accommodationsData.map((item, index) => ({ ...item, index })).find(accommodation => accommodation.id === id);
    if (accommodation) {
      const telemetryHotelInfo = {
        hotelName: accommodation.displayName || '',
        isHotelPreferred: accommodation.isFavorite || false,
        hotelPrice: accommodation.pricePerNight || undefined,
        hotelStrikeThroughPrice: accommodation.strikeoutPricePerNight || undefined,
        type: accommodation.type
      };
      telemetry.openAccommodationsDialog('accommodations', telemetryHotelInfo, label);
      setSelectedAccommodation(accommodation);
      onOpen();
    }
  });

  // Show map if have at least one venue or hotel with latitude or longitude
  const shouldShowMap = useMemo(() => (destination.latitude && destination.longitude) || accommodations?.find(accommodation => accommodation.latitude && accommodation.longitude), [
    destination,
    accommodations
  ]);

  const renderMap = useMemo(() => {
    return (
      <SkeletonGroup isReady={!loading} placeholder={<AccommodationsMapV2Skeleton isBrannanLayout={isBrannanLayout} />} width="100%" paddingX={6}>
        {shouldShowMap && (
          <AccommodationsMapV2
            key={`accommodations-guest-map-${destination.latitude}-${destination.longitude}`}
            showFullScreenMap={false}
            venueInfo={destination}
            accommodationsCombined={accommodationsData ?? []}
            pageTitle={pageTitle}
            onSelectHotel={id => onSelectHotel(id, 'viewPreview')}
            isBrannanLayout={isBrannanLayout}
            locationBeingHovered={locationBeingHovered}
            hideExploreCta={!isBrannanLayout}
            hideDirection
          />
        )}
      </SkeletonGroup>
    );
  }, [isBrannanLayout, loading, locationBeingHovered, onSelectHotel, pageTitle, destination, accommodationsData, shouldShowMap]);

  return (
    <JoyInteractableMapProvider>
      <Flex flexDirection="column" alignItems="center" width="100%" rowGap={{ _: 7, sm2: isBrannanLayout ? 7 : 8 }} {...customMapWrapperStyles}>
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          rowGap={{ _: 0, sm2: isBrannanLayout ? 7 : 0 }}
          paddingTop={{ _: 8, sm2: isBrannanLayout ? 5 : 0 }}
          {...customMapStyles}
        >
          <Flex width="100%" paddingX={{ _: isBrannanLayout ? 3 : 0, sm2: isBrannanLayout ? 7 : 0 }}>
            <Flex width="100%" display={isGridView ? 'grid' : 'flex'} flexDirection="column" gridTemplateColumns="1fr 2fr" gap={{ _: 7, sm2: isBrannanLayout ? 0 : 7 }}>
              {isGridView && renderMap}
              <SkeletonGroup marginY={{ sm2: isBrannanLayout ? -6 : 0 }} isReady={!loading} placeholder={<AccommodationsTileV2FavoriteSkeleton />}>
                {primaryAccommodation && (
                  <AccommodationsTileV2Favorite
                    accommodation={primaryAccommodation}
                    isFirst={true}
                    isLast={false}
                    locationInfo={destination}
                    eventHandle={eventHandle}
                    showDefaultData={showDefaultData}
                    setLocationBeingHovered={setLocationBeingHovered}
                  />
                )}
              </SkeletonGroup>
            </Flex>
          </Flex>
          <Flex width="100%" paddingX={{ _: isBrannanLayout ? 3 : 0, sm2: isBrannanLayout ? 7 : 0 }}>
            <Flex width="100%" flexDirection="column" gap={7}>
              <SkeletonGroup
                isReady={!loading}
                placeholder={
                  <>
                    <AccommodationsTileV2Skeleton />
                    <AccommodationsTileV2Skeleton />
                    <AccommodationsTileV2Skeleton />
                  </>
                }
                width="100%"
                display={isGridView ? 'grid' : 'flex'}
                flexDirection="column"
                gridTemplateColumns="repeat(3, minmax(0px, 1fr))"
              >
                {accommodations && accommodations?.length > 0 && (
                  <>
                    {accommodations.map((accommodation, idx) => {
                      return (
                        <AccommodationsTileV2
                          accommodation={accommodation}
                          key={`${accommodation.id}-${idx}`}
                          idx={idx + 1}
                          locationInfo={destination}
                          eventHandle={eventHandle}
                          showDefaultData={showDefaultData}
                          setLocationBeingHovered={setLocationBeingHovered}
                        />
                      );
                    })}
                  </>
                )}
              </SkeletonGroup>
              {!isGridView && renderMap}
            </Flex>
          </Flex>
        </Flex>
        {!travelMapComponentData.hideExploreCTA && (
          <ExploreOtherOptions accommodations={sortedHotelList} venue={destination || null} pageSlug={pageSlug} eventTimezone={eventTimezone} />
        )}
        <RedirectWithSafeLink
          href={safeLink}
          skipTelemetry
          onClose={() => {
            setSafeLink(undefined);
          }}
        />
        {selectedAccommodation && (
          <AccommodationsDialog
            isOpen={isOpen}
            onClose={onClose}
            accommodation={selectedAccommodation}
            showDefaultData={showDefaultData}
            shouldShowMap={!!shouldShowMap}
            venueInfo={destination}
            pageTitle={pageTitle}
            locationInfo={destination}
            eventHandle={eventHandle}
            eventPhoto={eventPhoto}
            setSafeLink={setSafeLink}
          />
        )}
      </Flex>
    </JoyInteractableMapProvider>
  );
};
