import React from 'react';
import { Box, TextV2 } from '@withjoy/joykit';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { AuxTitleContainer } from './AuxFrameTitle.styles';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useTranslation } from '@shared/core';
import { Category, EventPageType } from '@graphql/generated';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { display8ToEventTitleOverride, hed4ToSubHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { Textfit } from 'react-textfit';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

interface Props
  extends Readonly<{
    x: 'left' | 'middle' | 'right';
    y: 'top' | 'middle' | 'bottom';
    textLight: boolean;
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    page: Maybe<EventPageType>;
    pageSlug: string;
    welcomePageId: string;
  }> {}

export const AuxFrameTitle: React.FC<Props> = props => {
  const { x, y, textLight, title, subtitle, page, pageSlug, welcomePageId } = props;
  const textAlign = x === 'middle' ? 'center' : undefined;

  const { t2 } = useTranslation('joykit');
  const inlineEditorTrans = t2('inlineEditor');

  const isMobile = useIsMobileScreen();

  const handleEditTextClick = useEventCallback((action: 'editText' | 'editTextArea', textContent: string, label: string, pageId?: string) => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value:
        action === 'editText'
          ? { action: 'editText', inlineEditData: { textContent, label, validationConstraints: { maxLength: 30 } } }
          : { action: 'editTextArea', inlineEditData: { textContent, label, pageId, validationConstraints: { maxLength: 140 } } }
    });
  });

  const handleEditFontClick = useEventCallback((category: Category) => {
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editFont', inlineEditData: { category } }
    });
  });

  return (
    <AuxTitleContainer y={y} x={x} color={textLight ? 'white' : '#333'}>
      {title ? (
        <InlineEditor
          elementLabel={inlineEditorTrans.displayName}
          actionData={{
            edit: () => handleEditTextClick('editText', title, inlineEditorTrans.displayName),
            font: () => handleEditFontClick(Category.EVENT_TITLE)
          }}
          wrapperCSS={{
            zIndex: 1200
          }}
          componentName="eventDisplayName"
          pageName={page}
          pageSlug={pageSlug}
        >
          <GuestSiteTypographyOverride override={display8ToEventTitleOverride}>
            {isMobile ? (
              <TextV2
                typographyVariant="display8"
                textAlign={textAlign}
                tagName="div"
                fontSize="auto"
                display="flex"
                width="100%"
                height="100%"
                justifyContent="flex-end"
                flexDirection="column"
                __css={{
                  ['& > div']: {
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column'
                  }
                }}
              >
                <Textfit
                  onReady={() => {
                    // This is a workaround to force the Textfit to recalculate its size on first load
                    return null;
                  }}
                >
                  {title}
                </Textfit>
              </TextV2>
            ) : (
              <TextV2 typographyVariant="display8" textAlign={textAlign}>
                {title}
              </TextV2>
            )}
          </GuestSiteTypographyOverride>
        </InlineEditor>
      ) : null}
      {subtitle ? (
        <Box marginTop={[5]}>
          <InlineEditor
            elementLabel={inlineEditorTrans.greetings}
            actionData={{
              edit: () => handleEditTextClick('editTextArea', subtitle, inlineEditorTrans.greetings, welcomePageId),
              font: () => handleEditFontClick(Category.SUB_HEADING)
            }}
            wrapperCSS={{
              zIndex: 1200
            }}
            componentName="greetings"
            pageName={page}
            pageSlug={pageSlug}
          >
            <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride}>
              <TextV2 typographyVariant="hed4" textAlign={textAlign} whiteSpace="pre-wrap">
                {subtitle}
              </TextV2>
            </GuestSiteTypographyOverride>
          </InlineEditor>
        </Box>
      ) : null}
    </AuxTitleContainer>
  );
};
